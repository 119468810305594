/// <reference types="vite-plugin-svgr/client" />
import React, { useMemo } from 'react';
import cx from 'classnames';
import { useFragment } from 'react-relay';
import { graphql } from "react-relay";

import styles from './DocumentList.module.scss';
import { DocumentList_documents$key } from './__generated__/DocumentList_documents.graphql';
import { DocumentListItem_document$key } from './__generated__/DocumentListItem_document.graphql';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faCode } from '@fortawesome/pro-light-svg-icons';

import ThumbsDownIcon from '../../../assets/thumb-down.svg?react';
import ThumbsUpIcon from '../../../assets/thumb-up.svg?react';
import Button from 'components/Button/Button';
import useTranslate from 'hooks/useTranslate';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  documents: DocumentList_documents$key
  mode?: "download"
}

export default function DocumentList(props: Props) {
  const documents = useFragment(graphql`
    fragment DocumentList_documents on SignatoryDocumentConnection {
      edges {
        node {
          id
        }

        ...DocumentListItem_document
      }
    }
  `, props.documents);


  return (
    <div className={styles['document-list']}>
      {documents.edges.map((edge, index) => (
        <DocumentListItem
          key={edge.node.id}
          edge={edge}
          index={index}
          mode={props.mode}
        />
      ))}
    </div>
  )
}

interface DocumentListItemProps {
  edge: DocumentListItem_document$key,
  index: number
  mode?: "download"
}
export function DocumentListItem(props: DocumentListItemProps) {
  const translate = useTranslate();
  const mode = props.mode ?? "sign";
  const location = useLocation();
  const navigate = useNavigate();

  const edge = useFragment(graphql`
    fragment DocumentListItem_document on SignatoryDocumentEdge {
      status
      node {
        __typename
        id
        title
      }
    }
  `, props.edge);

  const isDesktop = useMemo(() => window.matchMedia('(min-width: 992px)')?.matches ?? false, []);
  const handleClick = () => {
    navigate(`${ensureTrailingSlash(location.pathname)}documents/${edge.node.id}`);
  };

  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        className={cx(
          styles['document-list-item'],
          (mode === 'sign' ? styles[`document-list-item-${edge.status}`] : null)
        )}
        data-testid={edge.status ? `document-list-item-${edge.status}` : `document-list-item-${edge.node.id}`}
        title={edge.node.title}
      >
        <div className={styles.left}>
          <div className={styles.icon}>
            {edge.node.__typename === 'XmlDocument' ? (
              <FontAwesomeIcon icon={faCode} />
            ) : (
              <FontAwesomeIcon icon={faFilePdf} />
            )}
          </div>
          <div className={styles.text}>
            {isDesktop ? edge.node.title : trimText(edge.node.title, 38)}
          </div>
        </div>
        <div className={styles.right}>
          {mode === 'download' ? (
            <FontAwesomeIcon icon={faDownload} />
          ) : (
            <React.Fragment>
              {(edge.status === 'APPROVED' || edge.status === 'PREAPPROVED' || edge.status === 'SIGNED') ? (
                <ThumbsUpIcon />
              ) : edge.status === 'REJECTED' ? (
                <ThumbsDownIcon />
              ) : (
                <Button variant="primary" autoWidth={true} className={styles.openButton}>{translate('Open')}</Button>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const DOTS = '...' as const;
function trimText(input: string, length: number) {
  if ((input.length + DOTS.length) > length) {
    return input.slice(0, length) + '...';
  }
  return input;
}

function ensureTrailingSlash(input: string) {
  if (input.endsWith('/')) return input;
  return input + '/';
}