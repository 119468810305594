import React, { useState } from 'react';
import cx from 'classnames';
import {useLazyLoadQuery} from 'react-relay';
import { graphql } from "react-relay";
import { OAuth2Error } from '@criipto/verify-react';
import { faPen, faSignature } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { State } from '../../state';
import DrawableEvidenceProviderScreen, {DrawableEvidence} from './screens/DrawableEvidenceProviderScreen/DrawableEvidenceProviderScreen';
import styles from './SignScreen.module.scss';
import useTranslate from '../../hooks/useTranslate';
import Button from '../../components/Button/Button';
import CriiptoVerifyButtons from '../../components/CriiptoVerifyButtons/CriiptoVerifyButtons';
import useSignMutation, {SignInput, useSignState} from '../../mutations/sign';
import LoadingScreen from 'screens/LoadingScreen/LoadingScreen';
import EvidenceValidationErrorScreen from './screens/EvidenceValidationErrorScreen/EvidenceValidationErrorScreen';
import DuplicateEvidenceErrorScreen from './screens/DuplicateEvidenceErrorScreen/DuplicateEvidenceErrorScreen';
import ErrorScreen from 'screens/ErrorScreen/ErrorScreen';
import OAuth2ErrorScreen from '../SignatoryViewerScreen/screens/OAuth2ErrorScreen/OAuth2ErrorScreen';
import AnonymousViewerScreen from 'screens/AnonymousViewerScreen/AnonymousViewerScreen';
import { SignScreenQuery } from './__generated__/SignScreenQuery.graphql';
import { useNavigate } from 'react-router-dom';
import SignatoryBeacon from 'screens/SignatoryViewerScreen/components/SignatoryBeacon';
import AllOfEvidenceProviderScreen from './screens/AllOfEvidenceProviderScreen/AllOfEvidenceProviderScreen';
import { assertUnreachable } from 'utils';

export type {DrawableEvidence};

export type JWTEvidence = {
  type: "jwt"
  id: string
  jwt: string
}
export type AllOfEvidence = {
  type: "allOf",
  id: string
  jwt?: JWTEvidence
  drawable?: DrawableEvidence
}
export type Evidence = DrawableEvidence | JWTEvidence | AllOfEvidence

interface Props {
  state: State
  userAgent: string
}

type Viewer = Extract<SignScreenQuery["response"]["viewer"], {__typename: "SignatoryViewer"}>
type EvidenceProvider = Viewer["evidenceProviders"][0];

export default function SignScreen(props: Props) {
  const translate = useTranslate();
  const [oAuth2Error, setOAuth2Error] = useState<OAuth2Error | null>(null);
  const [showDrawable, setDrawable] = useState<EvidenceProvider | null>(null);
  const navigate = useNavigate();
  const [resetKey, setResetKey] = useState<string | null>(null);

  const data = useLazyLoadQuery<SignScreenQuery>(
    graphql`
      query SignScreenQuery {
        viewer {
          __typename

          ... on SignatoryViewer {
            status
            signer

            documents {
              edges {
                status
              }
            }
            evidenceProviders {
              __typename
              ...CriiptoVerifyButtons_evidenceProvider
              ... on OidcJWTSignatureEvidenceProvider {
                id
              }
              ... on CriiptoVerifySignatureEvidenceProvider {
                id
              }
              ... on DrawableSignatureEvidenceProvider {
                id
                ...DrawableEvidenceProviderScreen
              }
              ... on AllOfSignatureEvidenceProvider {
                id
                ...AllOfEvidenceProviderScreen_evidenceProvider
              }
            }
            ...SignatoryBeacon_Single_viewer
          }
          ... on BatchSignatoryViewer {
            status
            signer

            documents {
              edges {
                status
              }
            }
            evidenceProviders {
              __typename
              ...CriiptoVerifyButtons_evidenceProvider
              ... on OidcJWTSignatureEvidenceProvider {
                id
              }
              ... on CriiptoVerifySignatureEvidenceProvider {
                id
              }
              ... on DrawableSignatureEvidenceProvider {
                id
                ...DrawableEvidenceProviderScreen
              }
              ... on AllOfSignatureEvidenceProvider {
                id
                ...AllOfEvidenceProviderScreen_evidenceProvider
              }
            }
            ...SignatoryBeacon_Batch_viewer
          }
        }
      }
    `,
    {}
  );

  const [signExecutor, signStatus] = useSignMutation();
  const [signState] = useSignState();
  const handleReset = () => {
    signStatus.reset();
    setOAuth2Error(null);
    setResetKey(Math.random().toString());

    const query = new URLSearchParams(window.location.search);
    query.delete('error');
    query.delete('error_description');
    window.history.replaceState(null, "", window.location.origin + window.location.pathname + `?${query.toString()}${window.location.hash}`);
  };

  const handleEvidence = (evidence: Evidence) => {
    const input : SignInput | null = evidence.type === 'jwt' ? {
      ...signState?.input,
      id: evidence.id,
      oidc: {
        jwt: evidence.jwt
      },
      criiptoVerify: {
        jwt: evidence.jwt
      }
    } : evidence.type === 'drawable' ? {
      ...signState?.input,
      id: evidence.id,
      drawable: {
        image: evidence.image,
        name: ('name' in evidence) ? evidence.name : null
      }
    } : evidence.type === 'allOf' ? {
      ...signState?.input,
      id: evidence.id,
      allOf: {
        criiptoVerify: evidence.jwt ? {
          jwt: evidence.jwt.jwt
        } : undefined,
        drawable: evidence.drawable ? {
          image: evidence.drawable.image,
          name: ('name' in evidence.drawable) ? evidence.drawable.name : null
        } : undefined,
      }
    }: null;

    if (!input) throw new Error('Invalid input');

    signExecutor.executePromise({
      input
    }).then(() => {
      navigate('/');
    });
  }

  const handleCancel = () => {
    navigate('/');
  };

  const viewer = data.viewer;
  if (viewer.__typename !== 'SignatoryViewer' && viewer.__typename !== 'BatchSignatoryViewer') return <AnonymousViewerScreen />

  const componentViewer =
    viewer.__typename === 'SignatoryViewer'
      ? { singleSignatory: viewer }
      : viewer.__typename === 'BatchSignatoryViewer'
        ? { batchSignatory: viewer }
        : assertUnreachable(viewer);

  const show = true;
  const isSigner = viewer.signer;
  const allPreapproved = viewer.documents.edges.reduce((memo, edge) => memo && edge.status === 'PREAPPROVED', true);
  const onlyOIDC = (viewer.evidenceProviders.length === 1 && viewer.evidenceProviders.find(s => s.__typename === 'OidcJWTSignatureEvidenceProvider')) ?? false;
  const onlyCriiptoVerify = (viewer.evidenceProviders.length === 1 && viewer.evidenceProviders.find(s => s.__typename === 'CriiptoVerifySignatureEvidenceProvider')) ?? false;
  const onlyAllOf = (viewer.evidenceProviders.length === 1 && viewer.evidenceProviders.find(s => s.__typename === 'AllOfSignatureEvidenceProvider')) ?? false;
  const mayImmediateRedirect = (isSigner && (onlyOIDC || onlyCriiptoVerify) && true) && (allPreapproved || show) && !props.state.get().action;

  /* If drawable has been explicitely selected or it is the only one available, show the drawable screen */
  const onlyDrawable : EvidenceProvider | null =
    (viewer.evidenceProviders.length === 1 && viewer.evidenceProviders.find(s=> s.__typename === 'DrawableSignatureEvidenceProvider')) ||
    null;
  const drawable = showDrawable || onlyDrawable;

  const error = signStatus.error;

  if (oAuth2Error) {
    return (
      <div className={cx(styles['full-screen'], {[styles.hidden]: !show})}>
        <OAuth2ErrorScreen
          onRetry={handleReset}
          error={oAuth2Error}
        />
      </div>
    );
  }

  if (signStatus.pending) {
    return (
      <LoadingScreen text={translate('We are processing your data...')} />
    );
  }
  if (error?.message === 'Signatory evidence validation failed') {
    return (
      <EvidenceValidationErrorScreen onRetry={handleReset} />
    );
  }
  if (error?.message === 'Signatory evidence already used to sign previously') {
    return (
      <DuplicateEvidenceErrorScreen onRetry={handleReset} />
    );
  }
  if (error) {
    return (
      <ErrorScreen error={error} />
    );
  }
  if (!isSigner) {
    return (
      <React.Fragment>
        <SignatoryBeacon viewer={componentViewer} />
        <LoadingScreen text={translate('Hold on a second...')} />
      </React.Fragment>
    );
  }

  if (onlyAllOf) {
    return (
      <AllOfEvidenceProviderScreen
        resetKey={resetKey}
        userAgent={props.userAgent}
        state={props.state}
        evidenceProvider={onlyAllOf}
        onCancel={handleCancel}
        onError={setOAuth2Error}
        onEvidence={handleEvidence}
      />
    )
  }

  if (show && drawable) {
    /** If all documents are preapproved, and only drawable is allowed, the back button does nothing */
    const canCancel = !(allPreapproved && onlyDrawable);
    const cancelDrawable =
      canCancel ?
        onlyDrawable ?
          handleCancel :
          () => setDrawable(null)
        : undefined;
    return (
      <DrawableEvidenceProviderScreen
        evidenceProvider={drawable}
        onCancel={cancelDrawable}
        onEvidence={handleEvidence}
      />
    );
  }

  return (
    <div className={styles['sign-screen']}>
      <header>
        <i className="sign-icon">
          <FontAwesomeIcon icon={faSignature} />
          <FontAwesomeIcon icon={faPen} />
        </i>
        <h1>{translate('Choose how you want to sign')}</h1>
      </header>

      <div className={styles['providers']}>
        {viewer.evidenceProviders.map(evidenceProvider => (
          <React.Fragment key={evidenceProvider.__typename}>
            <CriiptoVerifyButtons
              userAgent={props.userAgent}
              evidenceProvider={evidenceProvider}
              onEvidence={handleEvidence}
              onError={setOAuth2Error}
              state={props.state}
              mayImmediateRedirect={mayImmediateRedirect}
              prompt="login"
              callbackRoute="/callback/sign"
            />
            {evidenceProvider.__typename === 'DrawableSignatureEvidenceProvider' && show && (
              <Button
                large={true}
                variant={"default"}
                className={styles.button}
                onClick={() => setDrawable(evidenceProvider)}
                icon={faSignature}
              >
                {translate('Draw your signature')}
              </Button>
            )}
          </React.Fragment>
        ))}
      </div>

      {!allPreapproved && (
        <Button variant="default" onClick={handleCancel}>
          {translate('Cancel')}
        </Button>
      )}
    </div>
  );
}