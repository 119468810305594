import {useEffect, useMemo} from 'react';
import {useFragment} from 'react-relay';
import cx from 'classnames';
import { graphql } from "react-relay";

import type { ReceiptScreen_Single_viewer$key } from "./__generated__/ReceiptScreen_Single_viewer.graphql";
import type { ReceiptScreen_Batch_viewer$key } from "./__generated__/ReceiptScreen_Batch_viewer.graphql";

import styles from './ReceiptScreen.module.scss';

import ThumbsDownIcon from 'components/ThumbsDownIcon/ThumbsDownIcon';
import ThumbsUpIcon from 'components/ThumbsUpIcon/ThumbsUpIcon';
import useTranslate from 'hooks/useTranslate';
import { assertUnreachable } from 'utils';

type Viewer = {
  viewer: {singleSignatory: ReceiptScreen_Single_viewer$key} | {batchSignatory: ReceiptScreen_Batch_viewer$key}
}

type Props = {
  onNavigate: (url: string) => void
} & Viewer;

const SingleSignatoryViewerFragment = graphql`
  fragment ReceiptScreen_Single_viewer on SignatoryViewer {
    status

    ui {
      signatoryRedirectUri
    }

    signatoryId
  }
`;

const BatchSignatoryViewerFragment = graphql`
  fragment ReceiptScreen_Batch_viewer on BatchSignatoryViewer {
    status

    ui {
      signatoryRedirectUri
    }

    batchSignatoryId
  }
`;

export default function ReceiptScreen(props: Props) {
  const {onNavigate} = props;
  const translate = useTranslate();

  const [fragment, data] = useMemo(() => {
    if ('singleSignatory' in props.viewer) {
      return [SingleSignatoryViewerFragment, props.viewer.singleSignatory];
    }

    if ('batchSignatory' in props.viewer) {
      return [BatchSignatoryViewerFragment, props.viewer.batchSignatory];
    }

    assertUnreachable(props.viewer);
  }, [props.viewer]);

  const viewer = useFragment(
    fragment,
    data
  );

  const willRedirect = !!viewer.ui.signatoryRedirectUri;

  useEffect(() => {
    if (!viewer.ui.signatoryRedirectUri) return;

    const url = new URL(viewer.ui.signatoryRedirectUri!);

    if ('signatoryId' in viewer)
      url.searchParams.append('signatory_id', viewer.signatoryId);

    if ('batchSignatoryId' in viewer)
      url.searchParams.append('batch_signatory_id', viewer.batchSignatoryId);

    url.searchParams.append('signatory_status', viewer.status);

    const timeout = setTimeout(() => {
      onNavigate(url.toString());
    }, 500);

    return () => clearTimeout(timeout);
  }, [viewer, onNavigate]);

  if (viewer.status === "SIGNED") {
    return (
      <div className={cx(styles['receipt-screen'], styles[viewer.status])} data-testid="signatory-receipt-screen-approved">
        <h1>
          {translate('Signed')}
          {willRedirect && (<span>. <br />{translate('You will be redirected...')}</span>)}
        </h1>
        <ThumbsUpIcon />
      </div>
    );
  }

  if (viewer.status === "REJECTED") {
    return (
      <div className={cx(styles['receipt-screen'], styles[viewer.status])} data-testid="signatory-receipt-screen-rejected">
        <h1>
          {translate('You have not signed')}
          {willRedirect && (<span>. <br />{translate('You will be redirected...')}</span>)}
        </h1>
        <ThumbsDownIcon />
      </div>
    );
  }

  return null;
}