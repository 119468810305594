/**
 * @generated SignedSource<<afa33aa77d2a2b181f5fe57f5ab18d60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SignatoryBeacon_Batch_viewer$data = {
  readonly documents: {
    readonly edges: ReadonlyArray<{
      readonly status: SignatoryDocumentStatus | null;
    }>;
  };
  readonly signer: boolean;
  readonly status: SignatoryStatus;
  readonly " $fragmentType": "SignatoryBeacon_Batch_viewer";
};
export type SignatoryBeacon_Batch_viewer$key = {
  readonly " $data"?: SignatoryBeacon_Batch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignatoryBeacon_Batch_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignatoryBeacon_Batch_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatoryDocumentConnection",
      "kind": "LinkedField",
      "name": "documents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatoryDocumentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatchSignatoryViewer",
  "abstractKey": null
};
})();

(node as any).hash = "621ddabd732f8b4d3abad3c3d84e5aee";

export default node;
