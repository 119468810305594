import {useLazyLoadQuery} from 'react-relay';
import React from 'react';
import cx from 'classnames';
import { graphql } from "react-relay";

import AnonymousViewerScreen from '../AnonymousViewerScreen';

import { DownloadScreenQuery, DownloadVerificationInput } from './__generated__/DownloadScreenQuery.graphql';
import { useState, useEffect } from 'react';
import DownloadUnavailableScreen from './screens/DownloadUnavailableScreen';
import CriiptoVerifyButtons from '../../components/CriiptoVerifyButtons';

import styles from './DownloadScreen.module.scss';
import useTranslate from 'hooks/useTranslate';
import { State } from '../../state';
import { OAuth2Error } from '@criipto/verify-react';
import OAuth2ErrorScreen from 'screens/SignatoryViewerScreen/screens/OAuth2ErrorScreen/OAuth2ErrorScreen';
import { Evidence } from 'screens/SignScreen/SignScreen';
import DownloadVerificationErrorScreen from './screens/DownloadVerificationErrorScreen';
import DownloadDocumentList from './components/DownloadDocumentList';
import { useOutlet } from 'react-router-dom';
import DownloadExpiredScreen from './screens/DownloadExpiredScreen';
import useTrackSignatoryMutation from 'mutations/trackSignatory';

interface Props {
  state: State
  userAgent: string
}

export default function DownloadScreen(props: Props) {
  const translate = useTranslate();
  const [verification, setVerification] = useState<DownloadVerificationInput | null>(null);
  const [oAuth2Error, setOAuth2Error] = useState<OAuth2Error | null>(null);
  const outlet = useOutlet();
  const data = useLazyLoadQuery<DownloadScreenQuery>(
    graphql`
      query DownloadScreenQuery($verification: DownloadVerificationInput) {
        viewer {
          __typename

          ... on SignatoryViewer {
            ui {
              logo {
                src
                href
              }
            }

            download(verification: $verification) {
              expired
              verificationRequired
              verificationEvidenceProvider {
                __typename
                ...CriiptoVerifyButtons_evidenceProvider
                ... on OidcJWTSignatureEvidenceProvider {
                  id
                }
                ... on CriiptoVerifySignatureEvidenceProvider {
                  id
                }
                ... on DrawableSignatureEvidenceProvider {
                  id
                  requireName
                }
              }

              documents {
                ...DownloadDocumentList_documents
              }
            }
          }

          ... on UnvalidatedSignatoryViewer {
            ui {
              logo {
                src
                href
              }
            }

            download(verification: $verification) {
              expired
              verificationRequired
              verificationEvidenceProvider {
                __typename
                ...CriiptoVerifyButtons_evidenceProvider
                ... on OidcJWTSignatureEvidenceProvider {
                  id
                }
                ... on CriiptoVerifySignatureEvidenceProvider {
                  id
                }
                ... on DrawableSignatureEvidenceProvider {
                  id
                  requireName
                }
              }

              documents {
                ...DownloadDocumentList_documents
              }
            }
          }
        }
      }
    `,
    {
      verification
    }
  );

  const {viewer} = data;

  const [trackSignatory] = useTrackSignatoryMutation();
  useEffect(() => {
    trackSignatory.execute({
      input: {
        event: 'DOWNLOAD_LINK_OPENED'
      }
    })
  }, [trackSignatory]);

  if (viewer.__typename !== 'SignatoryViewer'  && viewer.__typename !== 'UnvalidatedSignatoryViewer') return <AnonymousViewerScreen />;

  const unavailable = !viewer.download || !viewer.download?.verificationEvidenceProvider;
  const verificationProviders = ['OidcJWTSignatureEvidenceProvider', 'CriiptoVerifySignatureEvidenceProvider'];

  if (outlet) return outlet;

  if (viewer.download?.expired === true) {
    return <DownloadExpiredScreen />
  }

  if (unavailable || !viewer.download) {
    return <DownloadUnavailableScreen />
  }

  if (oAuth2Error) {
    return (
      <OAuth2ErrorScreen
        error={oAuth2Error}
        onRetry={() => setOAuth2Error(null)}
        headline={translate('An error occurred while authenticating your e-ID.')}
      />
    )
  }

  const handleEvidence = (evidence: Evidence) => {
    setVerification({
      oidc: evidence.type === 'jwt' ? {
        jwt: evidence.jwt
      } : undefined,
      criiptoVerify: evidence.type === 'jwt' ? {
        jwt: evidence.jwt
      } : undefined
    });
  }

  if (verification && viewer.download.documents === null) {
    return <DownloadVerificationErrorScreen onRetry={() => setVerification(null)} />
  }

  if (viewer.download.verificationRequired && !verificationProviders.includes(viewer.download.verificationEvidenceProvider.__typename)) {
    return <DownloadUnavailableScreen />
  }

  return (
    <React.Fragment>
      <div className={styles['signatory-download-screen']} data-testid="signatory-download-screen">
        <header className={cx({[styles['with-logo']]: !!viewer.ui.logo})}>
          {viewer.ui.logo ? (
            <div className={styles.logo}>
              {viewer.ui.logo.href ? (
                <a href={viewer.ui.logo.href}>
                  <img src={viewer.ui.logo.src} alt="" />
                </a>
              ) : (
                <img src={viewer.ui.logo.src} alt="" />
              )}
            </div>
          ) : null}
          <h1>
            {viewer.download.documents ? translate('Download documents') : translate('Verify your identity to download documents')}
          </h1>
        </header>

        {viewer.download.documents ? (
          <DownloadDocumentList
            documents={viewer.download.documents}
          />
        ) : (
          <div className={styles.providers}>
            <CriiptoVerifyButtons
              userAgent={props.userAgent}
              evidenceProvider={viewer.download.verificationEvidenceProvider}
              onEvidence={handleEvidence}
              onError={setOAuth2Error}
              state={props.state}
              mayImmediateRedirect={false}
              action="login"
              prompt="login"
              callbackRoute="/callback/download"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}