import cx, {Argument} from 'classnames';

export type NoRefs<T> = T extends Record<string, unknown>
  ? Omit<T, ' $refType' | ' $fragmentRefs'>
  : T;

export type ExtractRelayEdge<
  T extends { edges: ReadonlyArray<{ node: any | null } | null> | null } | null
> = NoRefs<
  NonNullable<NonNullable<NonNullable<NonNullable<T>['edges']>[0]>>
>;

export type ExtractRelayEdgeNode<
  T extends { edges: ReadonlyArray<{ node: any | null } | null> | null } | null
> = NoRefs<
  NonNullable<NonNullable<NonNullable<NonNullable<T>['edges']>[0]>['node']>
>;

export function cssClass(props: {public: Argument[], private: Argument[]}) {
  return cx(cx(...props.private), cx(...props.public));
}

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}