/**
 * @generated SignedSource<<dc9d633ad4fa086d289571d5ecc32949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignatoryBeaconInput = {
  lastActionAt: any;
};
export type SignatoryBeaconMutation$variables = {
  input: SignatoryBeaconInput;
};
export type SignatoryBeaconMutation$data = {
  readonly signatoryBeacon: {
    readonly viewer: {
      readonly " $fragmentSpreads": FragmentRefs<"SignatoryViewerScreen_Batch_viewer" | "SignatoryViewerScreen_Single_viewer">;
    };
  } | null;
};
export type SignatoryBeaconMutation = {
  response: SignatoryBeaconMutation$data;
  variables: SignatoryBeaconMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signer",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SignatureOrderUI",
  "kind": "LinkedField",
  "name": "ui",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatureOrderUILogo",
      "kind": "LinkedField",
      "name": "logo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "src",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "href",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableRejection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatoryRedirectUri",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SignatoryDocumentConnection",
  "kind": "LinkedField",
  "name": "documents",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SignatoryDocumentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignatoryBeaconMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignatoryBeaconOutput",
        "kind": "LinkedField",
        "name": "signatoryBeacon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignatoryViewerScreen_Single_viewer"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignatoryViewerScreen_Batch_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignatoryBeaconMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignatoryBeaconOutput",
        "kind": "LinkedField",
        "name": "signatoryBeacon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatureOrderStatus",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryId",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "type": "SignatoryViewer",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "batchSignatoryId",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "type": "BatchSignatoryViewer",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cfd5d01b218c28471dbea43dcaa24b5a",
    "id": null,
    "metadata": {},
    "name": "SignatoryBeaconMutation",
    "operationKind": "mutation",
    "text": "mutation SignatoryBeaconMutation(\n  $input: SignatoryBeaconInput!\n) {\n  signatoryBeacon(input: $input) {\n    viewer {\n      __typename\n      ...SignatoryViewerScreen_Single_viewer\n      ...SignatoryViewerScreen_Batch_viewer\n      id\n    }\n  }\n}\n\nfragment DocumentListItem_document on SignatoryDocumentEdge {\n  status\n  node {\n    __typename\n    id\n    title\n  }\n}\n\nfragment DocumentList_documents on SignatoryDocumentConnection {\n  edges {\n    node {\n      __typename\n      id\n    }\n    ...DocumentListItem_document\n  }\n}\n\nfragment ReceiptScreen_Batch_viewer on BatchSignatoryViewer {\n  status\n  ui {\n    signatoryRedirectUri\n  }\n  batchSignatoryId\n}\n\nfragment ReceiptScreen_Single_viewer on SignatoryViewer {\n  status\n  ui {\n    signatoryRedirectUri\n  }\n  signatoryId\n}\n\nfragment SignatoryBeacon_Batch_viewer on BatchSignatoryViewer {\n  status\n  signer\n  documents {\n    edges {\n      status\n    }\n  }\n}\n\nfragment SignatoryBeacon_Single_viewer on SignatoryViewer {\n  status\n  signer\n  documents {\n    edges {\n      status\n    }\n  }\n}\n\nfragment SignatoryViewerScreen_Batch_viewer on BatchSignatoryViewer {\n  __typename\n  status\n  signer\n  ui {\n    logo {\n      src\n      href\n    }\n    disableRejection\n  }\n  ...ReceiptScreen_Batch_viewer\n  documents {\n    edges {\n      status\n      node {\n        __typename\n        id\n      }\n    }\n    ...DocumentList_documents\n  }\n  ...SignatoryBeacon_Batch_viewer\n}\n\nfragment SignatoryViewerScreen_Single_viewer on SignatoryViewer {\n  __typename\n  status\n  signatureOrderStatus\n  signer\n  ui {\n    logo {\n      src\n      href\n    }\n    disableRejection\n  }\n  ...ReceiptScreen_Single_viewer\n  documents {\n    edges {\n      status\n      node {\n        __typename\n        id\n      }\n    }\n    ...DocumentList_documents\n  }\n  ...SignatoryBeacon_Single_viewer\n}\n"
  }
};
})();

(node as any).hash = "ac441a51948c98398b91c73841396e38";

export default node;
