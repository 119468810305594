/**
 * @generated SignedSource<<c5612167d8fbfc20e31c08a66bf06fd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
export type SignInput = {
  allOf?: SignAllOfInput | null;
  criiptoVerify?: SignCriiptoVerifyInput | null;
  documents?: ReadonlyArray<SignDocumentInput> | null;
  drawable?: SignDrawableInput | null;
  id: string;
  noop?: boolean | null;
  oidc?: SignOidcInput | null;
};
export type SignAllOfInput = {
  criiptoVerify?: SignCriiptoVerifyInput | null;
  drawable?: SignDrawableInput | null;
  noop?: boolean | null;
  oidc?: SignOidcInput | null;
};
export type SignCriiptoVerifyInput = {
  jwt: string;
};
export type SignDrawableInput = {
  image: string;
  name?: string | null;
};
export type SignOidcInput = {
  jwt: string;
};
export type SignDocumentInput = {
  form?: SignDocumentFormInput | null;
  id: string;
};
export type SignDocumentFormInput = {
  fields: ReadonlyArray<SignDocumentFormFieldInput>;
};
export type SignDocumentFormFieldInput = {
  field: string;
  value: string;
};
export type signMutation$variables = {
  input: SignInput;
};
export type signMutation$data = {
  readonly sign: {
    readonly viewer: {
      readonly signer?: boolean;
      readonly status?: SignatoryStatus;
    };
  } | null;
};
export type signMutation = {
  response: signMutation$data;
  variables: signMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signer",
    "storageKey": null
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "SignatoryViewer",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": (v2/*: any*/),
  "type": "BatchSignatoryViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignOutput",
        "kind": "LinkedField",
        "name": "sign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignOutput",
        "kind": "LinkedField",
        "name": "sign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5072b573044109afbc93341ccf3fc105",
    "id": null,
    "metadata": {},
    "name": "signMutation",
    "operationKind": "mutation",
    "text": "mutation signMutation(\n  $input: SignInput!\n) {\n  sign(input: $input) {\n    viewer {\n      __typename\n      ... on SignatoryViewer {\n        status\n        signer\n      }\n      ... on BatchSignatoryViewer {\n        status\n        signer\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "069ba8645f6c8bcb2736e3ddf0f3f3f7";

export default node;
