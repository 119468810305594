/**
 * @generated SignedSource<<02ff6b0acbcffcdf7ef23487b494a4c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sign_state_single_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "sign_state_single_viewer";
};
export type sign_state_single_viewer$key = {
  readonly " $data"?: sign_state_single_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"sign_state_single_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sign_state_single_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "97aebf68e34ac3e07fd8b9cf6da2886c";

export default node;
