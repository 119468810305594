import { graphql } from "react-relay";

import useMutation from '../hooks/useMutation';
import {updateSignatoryDocumentStatusMutation} from './__generated__/updateSignatoryDocumentStatusMutation.graphql'

export default function useUpdateSignatoryDocumentStatus() {
  return useMutation<updateSignatoryDocumentStatusMutation>(graphql`
    mutation updateSignatoryDocumentStatusMutation($input: UpdateSignatoryDocumentStatusInput!) {
      updateSignatoryDocumentStatus(input: $input) {
        documentEdge {
          status
          node {
            id
            signatoryViewerStatus
          }
        }
        viewer {
          __typename
          ... on SignatoryViewer {
            status
            signer
          }
          ... on BatchSignatoryViewer {
            status
            signer
          }
        }
      }
    }
  `, {
    updater: (store, data) => {
      const updatedEdge = data.updateSignatoryDocumentStatus?.documentEdge;
      const viewer = store.getRoot().getLinkedRecord('viewer')?.getLinkedRecord('signatoryViewer') || store.getRoot().getLinkedRecord('viewer');
      const edges = viewer.getLinkedRecord('documents')?.getLinkedRecords('edges');

      edges?.forEach(edge => {
        if (edge.getLinkedRecord('node')?.getValue('id') === updatedEdge?.node.id) {
          edge.setValue(updatedEdge?.status, "status");
        }
      });

      if (store.getRoot().getLinkedRecord('viewer')) {
        if (data.updateSignatoryDocumentStatus?.viewer.__typename !== 'SignatoryViewer' &&
            data.updateSignatoryDocumentStatus?.viewer.__typename !== 'BatchSignatoryViewer') return;
        store.getRoot().getLinkedRecord('viewer')?.setValue(data.updateSignatoryDocumentStatus?.viewer.signer, 'signer');
      }
    }
  });
};