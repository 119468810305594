/**
 * @generated SignedSource<<142d8d986a244751a8809e590f0428d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignatoryDocumentStatus = "APPROVED" | "OPENED" | "PREAPPROVED" | "REJECTED" | "SIGNED" | "%future added value";
export type SignatoryStatus = "DELETED" | "ERROR" | "OPEN" | "REJECTED" | "SIGNED" | "%future added value";
export type UpdateSignatoryDocumentStatusInput = {
  documentId: string;
  status: SignatoryDocumentStatus;
};
export type updateSignatoryDocumentStatusMutation$variables = {
  input: UpdateSignatoryDocumentStatusInput;
};
export type updateSignatoryDocumentStatusMutation$data = {
  readonly updateSignatoryDocumentStatus: {
    readonly documentEdge: {
      readonly node: {
        readonly id: string;
        readonly signatoryViewerStatus: SignatoryDocumentStatus | null;
      };
      readonly status: SignatoryDocumentStatus | null;
    };
    readonly viewer: {
      readonly __typename: "BatchSignatoryViewer";
      readonly signer: boolean;
      readonly status: SignatoryStatus;
    } | {
      readonly __typename: "SignatoryViewer";
      readonly signer: boolean;
      readonly status: SignatoryStatus;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  } | null;
};
export type updateSignatoryDocumentStatusMutation = {
  response: updateSignatoryDocumentStatusMutation$data;
  variables: updateSignatoryDocumentStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signatoryViewerStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signer",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "SignatoryViewer",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "BatchSignatoryViewer",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSignatoryDocumentStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSignatoryDocumentStatusOutput",
        "kind": "LinkedField",
        "name": "updateSignatoryDocumentStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignatoryDocumentEdge",
            "kind": "LinkedField",
            "name": "documentEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateSignatoryDocumentStatusMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSignatoryDocumentStatusOutput",
        "kind": "LinkedField",
        "name": "updateSignatoryDocumentStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignatoryDocumentEdge",
            "kind": "LinkedField",
            "name": "documentEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0731505ca184aa970706afa79e0f8e22",
    "id": null,
    "metadata": {},
    "name": "updateSignatoryDocumentStatusMutation",
    "operationKind": "mutation",
    "text": "mutation updateSignatoryDocumentStatusMutation(\n  $input: UpdateSignatoryDocumentStatusInput!\n) {\n  updateSignatoryDocumentStatus(input: $input) {\n    documentEdge {\n      status\n      node {\n        __typename\n        id\n        signatoryViewerStatus\n      }\n    }\n    viewer {\n      __typename\n      ... on SignatoryViewer {\n        status\n        signer\n      }\n      ... on BatchSignatoryViewer {\n        status\n        signer\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "935597c1a805769fa50c4a13a869cba9";

export default node;
