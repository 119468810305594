/**
 * @generated SignedSource<<9f33a1799101a19f0f0ca362f56ac60a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sign_state_batch_viewer$data = {
  readonly id: string;
  readonly " $fragmentType": "sign_state_batch_viewer";
};
export type sign_state_batch_viewer$key = {
  readonly " $data"?: sign_state_batch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"sign_state_batch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sign_state_batch_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BatchSignatoryViewer",
  "abstractKey": null
};

(node as any).hash = "548fde2d5f3258e359a2dafdf16975c2";

export default node;
