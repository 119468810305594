import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { graphql, useFragment } from "react-relay";
import useMutation from '../hooks/useMutation';
import {signMutation, SignInput, SignDocumentInput, SignDocumentFormFieldInput} from './__generated__/signMutation.graphql'
import type { sign_state_single_viewer$key } from './__generated__/sign_state_single_viewer.graphql';
import type { sign_state_batch_viewer$key } from './__generated__/sign_state_batch_viewer.graphql';
import { assertUnreachable } from 'utils';

export type {SignInput, SignDocumentFormFieldInput};
export default function useSign() {
  return useMutation<signMutation>(graphql`
    mutation signMutation($input: SignInput!) {
      sign(input: $input) {
        viewer {
          ... on SignatoryViewer {
            status
            signer
          }
          ... on BatchSignatoryViewer {
            status
            signer
          }
        }
      }
    }
  `);
};

export type SignState = {
  input: Partial<SignInput>
}
type SignStateContextType = {
  state: SignState | null
  setState: React.Dispatch<React.SetStateAction<SignState | null>>
}
export const SignStateContext = React.createContext<SignStateContextType | null>(null);

const SingleSignatoryViewerFragment = graphql`
  fragment sign_state_single_viewer on SignatoryViewer {
    id
  }
`;

const BatchSignatoryViewerFragment = graphql`
  fragment sign_state_batch_viewer on BatchSignatoryViewer {
    id
  }
`;

type Viewer = {
  viewer: { singleSignatory: sign_state_single_viewer$key } | { batchSignatory: sign_state_batch_viewer$key}
}

type Props = {
  children: React.ReactNode
} & Viewer;

export function SignStateProvider(props: Props) {
  const [fragment, data, kind] = useMemo(() => {
    if ('singleSignatory' in props.viewer) {
      return [SingleSignatoryViewerFragment, props.viewer.singleSignatory, 'single'];
    }

    if ('batchSignatory' in props.viewer) {
      return [BatchSignatoryViewerFragment, props.viewer.batchSignatory, 'batch'];
    }

    assertUnreachable(props.viewer);
  }, [props.viewer]);

  const viewer = useFragment(
    fragment,
    data
  );

  const [state, setState] = useState<SignState | null>(() => {
    const stored = sessionStorage.getItem(`sign_state_${kind}_${viewer.id}`);
    if (stored) return JSON.parse(stored) as SignState;
    return null;
  });

  useEffect(() => {
    sessionStorage.setItem(`sign_state_${kind}_${viewer.id}`, JSON.stringify(state));
  }, [state]);

  const value = useMemo(() => ({
    state,
    setState
  }), [state, setState]);

  return (
    <SignStateContext.Provider value={value}>
      {props.children}
    </SignStateContext.Provider>
  );
}

type SignStateHook = [SignStateContextType["state"], SignStateContextType["setState"]];
export function useSignState() : SignStateHook {
  const context = useContext(SignStateContext);
  if (!context) return useMemo<SignStateHook>(() => [null, () => {}], []);

  return useMemo<SignStateHook>(() => [context.state, context.setState], [context.state, context.setState]);
}

type DocumentStateHookValue = Omit<SignDocumentInput, 'id'> | null;
type DocumentStateHook = [DocumentStateHookValue, (state: DocumentStateHookValue) => void];
export function useDocumentState(documentId: string) : DocumentStateHook {
  const context = useContext(SignStateContext);
  if (!context) return useMemo<DocumentStateHook>(() => [null, () => {}], []);

  const state = useMemo(() => context.state?.input.documents?.find(s => s.id === documentId) ?? null, [context.state]);
  const setState = useCallback((document: DocumentStateHookValue) => {
    context.setState(state => ({
      ...state,
      input: {
        ...state?.input,
        documents: (state?.input.documents?.filter(s => s.id !== documentId) ?? []).concat(document ? [{id: documentId, ...document}] : [])
      }
    }));
  }, [context.state, context.setState]);
  return useMemo<DocumentStateHook>(() => [state, setState], [state, setState]);
}