import React from 'react';
import { SignatureOrderStatus } from 'screens/SignatoryViewerScreen/__generated__/SignatoryViewerScreen_viewer.graphql';
import useTranslate from '../../../../hooks/useTranslate';

import styles from './SignatureOrderNotOpen.module.scss';
import { assertUnreachable } from 'utils';

interface Props {
  status: SignatureOrderStatus
}

export default function SignatureOrderNotOpenScreen(props: Props) {
  const {status} = props;
  const translate = useTranslate();

  if (status === 'OPEN') return null;
  return (
    <div className={styles.screen}>
      {status === 'CANCELLED' ? (
        <h1>{translate('Signature order has been cancelled')}</h1>
      ) : status === 'EXPIRED' ? (
        <h1>{translate('Signature order has expired')}</h1>
      ) : (status === 'CLOSED' || status === '%future added value') ? (
        <h1>{translate('Signature order is unavailable')}</h1>
      ) : assertUnreachable(status)}
      <p>
        {translate('You are currently not able to sign any documents. Please contact the person or organization that invited you to sign.')}
      </p>
    </div>
  )
}