import React, { useEffect, useMemo, useRef } from 'react';
import { graphql } from "react-relay";
import {useFragment} from 'react-relay';
import useTranslate, {useLanguage, Language} from '../../hooks/useTranslate';
import { CriiptoVerifyButtons_evidenceProvider$key, CriiptoVerifyButtons_evidenceProvider$data } from './__generated__/CriiptoVerifyButtons_evidenceProvider.graphql';
import { Action, AuthMethodSelector, CriiptoVerifyProvider, filterAcrValues, OAuth2Error, QRCode, useCriiptoVerify } from '@criipto/verify-react';
import { State } from 'state';
import { Evidence } from '../../screens/SignScreen/SignScreen';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.scss';
import Button from '../Button/Button';
import styles from './CriiptoVerifyButtons.module.scss';

import '@criipto/verify-react/dist/criipto-verify-react.css';
import { Prompt } from '@criipto/auth-js';
import { getMobileOS } from 'device';
import LoadingScreen from 'screens/LoadingScreen/LoadingScreen';
import { assertUnreachable } from 'utils';

function languageToLocales(language: Language) {
  switch (language) {
    case "EN_US": return 'en';
    case "DA_DK": return 'da';
    case "SV_SE": return 'sv';
    case "NB_NO": return 'nb';
  }

  assertUnreachable(language);
}

interface ButtonsProps {
  id: Extract<CriiptoVerifyButtons_evidenceProvider$data, {__typename: 'CriiptoVerifySignatureEvidenceProvider'}>["id"],
  acrValues: Extract<CriiptoVerifyButtons_evidenceProvider$data, {__typename: 'CriiptoVerifySignatureEvidenceProvider'}>["acrValues"],
  alwaysRedirect: Extract<CriiptoVerifyButtons_evidenceProvider$data, {__typename: 'CriiptoVerifySignatureEvidenceProvider'}>["alwaysRedirect"],
  state: State,
  onEvidence: (evidence: Evidence) => void
  onError: (error: OAuth2Error) => void,
  mayImmediateRedirect: boolean
}
export function Buttons(props: ButtonsProps) {
  const {id, onError, onEvidence, mayImmediateRedirect, state} = props;
  const translate = useTranslate();
  const redirecting = useRef(false);

  // We default to desktop if for some reason browser does not support matching media queries with javascript
  const desktopMatch = window.matchMedia('(min-width: 992px)');
  const shouldRedirect = props.alwaysRedirect || (desktopMatch ? !desktopMatch.matches : true);

  const {result, error, acrValues: discoveredAcrValues, loginWithRedirect, isLoading, isInitializing} = useCriiptoVerify();
  const idToken = result && "id_token" in result ? result.id_token : null

  const acrValues =
    Array.isArray(props.acrValues) && props.acrValues.length ? props.acrValues : discoveredAcrValues;

  const singleAcrValue = acrValues ? filterAcrValues(acrValues).length === 1 ? filterAcrValues(acrValues)[0] : null : undefined;
  const shouldImmediateRedirect = (singleAcrValue && mayImmediateRedirect && !isLoading && !isInitializing && !result) ? true : false;

  useEffect(() => {
    if (!error) return;
    onError(error);
  }, [
    onError,
    error,
  ]);

  useEffect(() => {
    if (!idToken) return;
    onEvidence({type: 'jwt', id, jwt: idToken});
  }, [
    id,
    onEvidence,
    idToken
  ]);

  useEffect(() => {
    if (!singleAcrValue) return;
    if (!shouldImmediateRedirect) return;
    if (redirecting.current) return;

    state.save({
      action: 'sign'
    });
    loginWithRedirect({acrValues: singleAcrValue});
    redirecting.current = true;
  }, [singleAcrValue, shouldImmediateRedirect, loginWithRedirect, state])

  const handlePoup = (props: {onHide: () => void, acrValue: string}) => {
    if (shouldRedirect) return false;
    return <PopupBackdropModal show={true} onCancel={props.onHide} onRetry={() => loginWithRedirect({acrValues: props.acrValue})} />
  }

  if (isLoading || isInitializing) {
    return (
      <LoadingScreen />
    );
  }

  return (
    <React.Fragment>
      <AuthMethodSelector acrValues={acrValues} popup={handlePoup} />
      <QRCode margin={2} className={styles.qrElement} acrValues={acrValues}>
        {({qrElement, isAcknowledged, isCancelled, isEnabled, retry, error}) => (
          <React.Fragment>
            {isEnabled ? (
              <div className={styles.qrCode}>
                {isCancelled ? (
                  <p>
                    {translate('Sign flow on mobile device cancelled')}. <Button className={styles.qrButton} variant="transparent" onClick={retry}>{translate('Try again')}</Button>
                  </p>
                ) : error ? (
                  <p>
                    {translate('An error occurred')}: {error.message}. <Button className={styles.qrButton} variant="transparent" onClick={retry}>{translate('Try again')}</Button>
                  </p>
                ) : isAcknowledged ? (
                  <p>
                    {translate('Complete the signing flow on your mobile device')}. <Button className={styles.qrButton} variant="transparent" onClick={retry}>{translate('Try again')}</Button>
                  </p>
                ) : (
                  <React.Fragment>
                    <p>{translate('Or sign with your mobile device by scanning the QR code')}.</p>
                    {qrElement}
                  </React.Fragment>
                )}
              </div>
            ) : null}
          </React.Fragment>
        )}
      </QRCode>
    </React.Fragment>
  );
}

interface ContainerProps extends Omit<ButtonsProps, 'acrValues' | 'stateKey' | 'id' | 'alwaysRedirect'> {
  evidenceProvider: CriiptoVerifyButtons_evidenceProvider$key
  userAgent: string
  callbackRoute?: string
  action?: Action
  prompt?: Prompt
}
export default function Container(props: ContainerProps) {
  const {state} = props;
  const evidenceProvider = useFragment(
    graphql`
      fragment CriiptoVerifyButtons_evidenceProvider on SignatureEvidenceProvider {
        __typename
        ... on OidcJWTSignatureEvidenceProvider {
          id
          domain
          clientID
          acrValues
          alwaysRedirect
        }
        ... on CriiptoVerifySignatureEvidenceProvider {
          id
          domain
          clientID
          acrValues
          alwaysRedirect
          message
          loginHint
          scope
        }
      }
    `,
    props.evidenceProvider
  );

  const language = useLanguage();
  useEffect(() => {
    state.save();
  }, [state]);

  const loginHint = useMemo(() => {
    if ("loginHint" in evidenceProvider) {
      const loginHint = evidenceProvider.loginHint;
      if (!loginHint) return undefined;
      const os = getMobileOS(props.userAgent);
      if (!os) return loginHint;

      return loginHint.replace('{device.os}', os);
    }
    return undefined;
  }, [evidenceProvider, props.userAgent]);

  if (
    evidenceProvider.__typename !== 'CriiptoVerifySignatureEvidenceProvider' &&
    evidenceProvider.__typename !== 'OidcJWTSignatureEvidenceProvider'
  ) {
    return null;
  }

  return (
    <CriiptoVerifyProvider
      domain={evidenceProvider.domain}
      clientID={evidenceProvider.clientID}
      response="token"
      uiLocales={languageToLocales(language)}
      prompt={props.prompt}
      action={props.action ?? "sign"}
      state={state.key}
      redirectUri={window.location.origin + window.location.pathname + (props.callbackRoute ? `#${props.callbackRoute}` : (window.location.hash || '#/'))}
      message={"message" in evidenceProvider ? (evidenceProvider.message ?? undefined) : undefined}
      loginHint={loginHint}
      scope={"scope" in evidenceProvider ? (evidenceProvider.scope ?? undefined) : undefined}
      criiptoSdk={"criipto_signatures_frontend"}
    >
      <Buttons {...props} acrValues={evidenceProvider.acrValues} alwaysRedirect={evidenceProvider.alwaysRedirect} id={evidenceProvider.id} />
    </CriiptoVerifyProvider>
  )
}
export function PopupBackdropModal(props: {show: boolean, onCancel: () => void, onRetry: () => void}) {
  const translate = useTranslate();
  return (
    <Modal
      show={props.show}
      closeButton={false}
      backdrop={true}
      onHide={() => props.onCancel()}
      body={
        <p>{translate('Don\'t see the popup?')}</p>
      }
      footer={(
        <React.Fragment>
          <Button className={modalStyles.button} variant="default" onClick={() => props.onCancel()}>{translate('Cancel')}</Button>
          <Button className={modalStyles.button} variant="primary" onClick={() => props.onRetry()}>
            {translate('Try again')}
          </Button>
        </React.Fragment>
      )}
    />
  )
}
